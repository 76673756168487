import React from 'react';

export default ({ children }) => (
  <div
    style={{
      width: '85vw'
    }}
  >
    {children}
  </div>
)