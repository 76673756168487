'use strict';
/* globals
	Atomics,
	SharedArrayBuffer,
*/

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.weak-set");

require("core-js/modules/es6.weak-map");

require("core-js/modules/es6.typed.uint32-array");

require("core-js/modules/es6.typed.uint16-array");

require("core-js/modules/es6.typed.uint8-clamped-array");

require("core-js/modules/es6.set");

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.promise");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.date.to-string");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.map");

require("core-js/modules/es6.typed.int32-array");

require("core-js/modules/es6.typed.int16-array");

require("core-js/modules/es6.typed.int8-array");

require("core-js/modules/es6.typed.float64-array");

require("core-js/modules/es6.typed.float32-array");

require("core-js/modules/es6.typed.data-view");

require("core-js/modules/es6.array.for-each");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.typed.uint8-array");

var undefined; // eslint-disable-line no-shadow-restricted-names

var $TypeError = TypeError;
var ThrowTypeError = Object.getOwnPropertyDescriptor ? function () {
  return Object.getOwnPropertyDescriptor(arguments, 'callee').get;
}() : function () {
  throw new $TypeError();
};

var hasSymbols = require('has-symbols')();

var getProto = Object.getPrototypeOf || function (x) {
  return x.__proto__;
}; // eslint-disable-line no-proto


var generator; // = function * () {};

var generatorFunction = generator ? getProto(generator) : undefined;
var asyncFn; // async function() {};

var asyncFunction = asyncFn ? asyncFn.constructor : undefined;
var asyncGen; // async function * () {};

var asyncGenFunction = asyncGen ? getProto(asyncGen) : undefined;
var asyncGenIterator = asyncGen ? asyncGen() : undefined;
var TypedArray = typeof Uint8Array === 'undefined' ? undefined : getProto(Uint8Array);
var INTRINSICS = {
  '$ %Array%': Array,
  '$ %ArrayBuffer%': typeof ArrayBuffer === 'undefined' ? undefined : ArrayBuffer,
  '$ %ArrayBufferPrototype%': typeof ArrayBuffer === 'undefined' ? undefined : ArrayBuffer.prototype,
  '$ %ArrayIteratorPrototype%': hasSymbols ? getProto([][Symbol.iterator]()) : undefined,
  '$ %ArrayPrototype%': Array.prototype,
  '$ %ArrayProto_entries%': Array.prototype.entries,
  '$ %ArrayProto_forEach%': Array.prototype.forEach,
  '$ %ArrayProto_keys%': Array.prototype.keys,
  '$ %ArrayProto_values%': Array.prototype.values,
  '$ %AsyncFromSyncIteratorPrototype%': undefined,
  '$ %AsyncFunction%': asyncFunction,
  '$ %AsyncFunctionPrototype%': asyncFunction ? asyncFunction.prototype : undefined,
  '$ %AsyncGenerator%': asyncGen ? getProto(asyncGenIterator) : undefined,
  '$ %AsyncGeneratorFunction%': asyncGenFunction,
  '$ %AsyncGeneratorPrototype%': asyncGenFunction ? asyncGenFunction.prototype : undefined,
  '$ %AsyncIteratorPrototype%': asyncGenIterator && hasSymbols && Symbol.asyncIterator ? asyncGenIterator[Symbol.asyncIterator]() : undefined,
  '$ %Atomics%': typeof Atomics === 'undefined' ? undefined : Atomics,
  '$ %Boolean%': Boolean,
  '$ %BooleanPrototype%': Boolean.prototype,
  '$ %DataView%': typeof DataView === 'undefined' ? undefined : DataView,
  '$ %DataViewPrototype%': typeof DataView === 'undefined' ? undefined : DataView.prototype,
  '$ %Date%': Date,
  '$ %DatePrototype%': Date.prototype,
  '$ %decodeURI%': decodeURI,
  '$ %decodeURIComponent%': decodeURIComponent,
  '$ %encodeURI%': encodeURI,
  '$ %encodeURIComponent%': encodeURIComponent,
  '$ %Error%': Error,
  '$ %ErrorPrototype%': Error.prototype,
  '$ %eval%': eval,
  // eslint-disable-line no-eval
  '$ %EvalError%': EvalError,
  '$ %EvalErrorPrototype%': EvalError.prototype,
  '$ %Float32Array%': typeof Float32Array === 'undefined' ? undefined : Float32Array,
  '$ %Float32ArrayPrototype%': typeof Float32Array === 'undefined' ? undefined : Float32Array.prototype,
  '$ %Float64Array%': typeof Float64Array === 'undefined' ? undefined : Float64Array,
  '$ %Float64ArrayPrototype%': typeof Float64Array === 'undefined' ? undefined : Float64Array.prototype,
  '$ %Function%': Function,
  '$ %FunctionPrototype%': Function.prototype,
  '$ %Generator%': generator ? getProto(generator()) : undefined,
  '$ %GeneratorFunction%': generatorFunction,
  '$ %GeneratorPrototype%': generatorFunction ? generatorFunction.prototype : undefined,
  '$ %Int8Array%': typeof Int8Array === 'undefined' ? undefined : Int8Array,
  '$ %Int8ArrayPrototype%': typeof Int8Array === 'undefined' ? undefined : Int8Array.prototype,
  '$ %Int16Array%': typeof Int16Array === 'undefined' ? undefined : Int16Array,
  '$ %Int16ArrayPrototype%': typeof Int16Array === 'undefined' ? undefined : Int8Array.prototype,
  '$ %Int32Array%': typeof Int32Array === 'undefined' ? undefined : Int32Array,
  '$ %Int32ArrayPrototype%': typeof Int32Array === 'undefined' ? undefined : Int32Array.prototype,
  '$ %isFinite%': isFinite,
  '$ %isNaN%': isNaN,
  '$ %IteratorPrototype%': hasSymbols ? getProto(getProto([][Symbol.iterator]())) : undefined,
  '$ %JSON%': JSON,
  '$ %JSONParse%': JSON.parse,
  '$ %Map%': typeof Map === 'undefined' ? undefined : Map,
  '$ %MapIteratorPrototype%': typeof Map === 'undefined' || !hasSymbols ? undefined : getProto(new Map()[Symbol.iterator]()),
  '$ %MapPrototype%': typeof Map === 'undefined' ? undefined : Map.prototype,
  '$ %Math%': Math,
  '$ %Number%': Number,
  '$ %NumberPrototype%': Number.prototype,
  '$ %Object%': Object,
  '$ %ObjectPrototype%': Object.prototype,
  '$ %ObjProto_toString%': Object.prototype.toString,
  '$ %ObjProto_valueOf%': Object.prototype.valueOf,
  '$ %parseFloat%': parseFloat,
  '$ %parseInt%': parseInt,
  '$ %Promise%': typeof Promise === 'undefined' ? undefined : Promise,
  '$ %PromisePrototype%': typeof Promise === 'undefined' ? undefined : Promise.prototype,
  '$ %PromiseProto_then%': typeof Promise === 'undefined' ? undefined : Promise.prototype.then,
  '$ %Promise_all%': typeof Promise === 'undefined' ? undefined : Promise.all,
  '$ %Promise_reject%': typeof Promise === 'undefined' ? undefined : Promise.reject,
  '$ %Promise_resolve%': typeof Promise === 'undefined' ? undefined : Promise.resolve,
  '$ %Proxy%': typeof Proxy === 'undefined' ? undefined : Proxy,
  '$ %RangeError%': RangeError,
  '$ %RangeErrorPrototype%': RangeError.prototype,
  '$ %ReferenceError%': ReferenceError,
  '$ %ReferenceErrorPrototype%': ReferenceError.prototype,
  '$ %Reflect%': typeof Reflect === 'undefined' ? undefined : Reflect,
  '$ %RegExp%': RegExp,
  '$ %RegExpPrototype%': RegExp.prototype,
  '$ %Set%': typeof Set === 'undefined' ? undefined : Set,
  '$ %SetIteratorPrototype%': typeof Set === 'undefined' || !hasSymbols ? undefined : getProto(new Set()[Symbol.iterator]()),
  '$ %SetPrototype%': typeof Set === 'undefined' ? undefined : Set.prototype,
  '$ %SharedArrayBuffer%': typeof SharedArrayBuffer === 'undefined' ? undefined : SharedArrayBuffer,
  '$ %SharedArrayBufferPrototype%': typeof SharedArrayBuffer === 'undefined' ? undefined : SharedArrayBuffer.prototype,
  '$ %String%': String,
  '$ %StringIteratorPrototype%': hasSymbols ? getProto(''[Symbol.iterator]()) : undefined,
  '$ %StringPrototype%': String.prototype,
  '$ %Symbol%': hasSymbols ? Symbol : undefined,
  '$ %SymbolPrototype%': hasSymbols ? Symbol.prototype : undefined,
  '$ %SyntaxError%': SyntaxError,
  '$ %SyntaxErrorPrototype%': SyntaxError.prototype,
  '$ %ThrowTypeError%': ThrowTypeError,
  '$ %TypedArray%': TypedArray,
  '$ %TypedArrayPrototype%': TypedArray ? TypedArray.prototype : undefined,
  '$ %TypeError%': $TypeError,
  '$ %TypeErrorPrototype%': $TypeError.prototype,
  '$ %Uint8Array%': typeof Uint8Array === 'undefined' ? undefined : Uint8Array,
  '$ %Uint8ArrayPrototype%': typeof Uint8Array === 'undefined' ? undefined : Uint8Array.prototype,
  '$ %Uint8ClampedArray%': typeof Uint8ClampedArray === 'undefined' ? undefined : Uint8ClampedArray,
  '$ %Uint8ClampedArrayPrototype%': typeof Uint8ClampedArray === 'undefined' ? undefined : Uint8ClampedArray.prototype,
  '$ %Uint16Array%': typeof Uint16Array === 'undefined' ? undefined : Uint16Array,
  '$ %Uint16ArrayPrototype%': typeof Uint16Array === 'undefined' ? undefined : Uint16Array.prototype,
  '$ %Uint32Array%': typeof Uint32Array === 'undefined' ? undefined : Uint32Array,
  '$ %Uint32ArrayPrototype%': typeof Uint32Array === 'undefined' ? undefined : Uint32Array.prototype,
  '$ %URIError%': URIError,
  '$ %URIErrorPrototype%': URIError.prototype,
  '$ %WeakMap%': typeof WeakMap === 'undefined' ? undefined : WeakMap,
  '$ %WeakMapPrototype%': typeof WeakMap === 'undefined' ? undefined : WeakMap.prototype,
  '$ %WeakSet%': typeof WeakSet === 'undefined' ? undefined : WeakSet,
  '$ %WeakSetPrototype%': typeof WeakSet === 'undefined' ? undefined : WeakSet.prototype
};

var bind = require('function-bind');

var $replace = bind.call(Function.call, String.prototype.replace);
/* adapted from https://github.com/lodash/lodash/blob/4.17.15/dist/lodash.js#L6735-L6744 */

var rePropName = /[^%.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|%$))/g;
var reEscapeChar = /\\(\\)?/g;
/** Used to match backslashes in property paths. */

var stringToPath = function stringToPath(string) {
  var result = [];
  $replace(string, rePropName, function (match, number, quote, subString) {
    result[result.length] = quote ? $replace(subString, reEscapeChar, '$1') : number || match;
  });
  return result;
};
/* end adaptation */


var getBaseIntrinsic = function getBaseIntrinsic(name, allowMissing) {
  var key = '$ ' + name;

  if (!(key in INTRINSICS)) {
    throw new SyntaxError('intrinsic ' + name + ' does not exist!');
  } // istanbul ignore if // hopefully this is impossible to test :-)


  if (typeof INTRINSICS[key] === 'undefined' && !allowMissing) {
    throw new $TypeError('intrinsic ' + name + ' exists, but is not available. Please file an issue!');
  }

  return INTRINSICS[key];
};

module.exports = function GetIntrinsic(name, allowMissing) {
  if (arguments.length > 1 && typeof allowMissing !== 'boolean') {
    throw new TypeError('"allowMissing" argument must be a boolean');
  }

  var parts = stringToPath(name);

  if (parts.length === 0) {
    return getBaseIntrinsic(name, allowMissing);
  }

  var value = getBaseIntrinsic('%' + parts[0] + '%', allowMissing);

  for (var i = 1; i < parts.length; i += 1) {
    if (value != null) {
      value = value[parts[i]];
    }
  }

  return value;
};