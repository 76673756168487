import { CodeSurfer } from "code-surfer";
import { Notes, Appear, Image } from "mdx-deck";
import { vsDark, nightOwl } from "@code-surfer/themes";
import ListLayout from "../../../../../../components/ListLayout";
import SocialFollow from "../../../../../../components/Social";
import CircuitBreakerImg from "../../../../../../images/Circuit_Breaker.png";
import React from 'react';
export default {
  CodeSurfer: CodeSurfer,
  Notes: Notes,
  Appear: Appear,
  Image: Image,
  vsDark: vsDark,
  nightOwl: nightOwl,
  ListLayout: ListLayout,
  SocialFollow: SocialFollow,
  CircuitBreakerImg: CircuitBreakerImg,
  React: React
};